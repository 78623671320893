import React, { lazy } from 'react';

import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { ProjectLayout } from './project/project-layout.component';
import { User } from '../../../__generated__/globalTypes';
import { ProjectSetup } from './project/project-setup/project-setup-stepper.component';
import { ProjectListLayout } from './project/project-list-layout.component';
import { ProjectListLayoutV2 } from './project/project-list-layout-v2.component';
import { NotFoundComponent } from '../_shared/components/error-pages';
import { ProjectBasicInfo } from './project/project-basic-info.component';
import { ExpertKnowledgeLayout, SummaryViewLayout } from './expert-knowledge';
import { ExecuteSolutionsContextProvider } from '../_shared/context/execute-solutions-context';
import ExecuteSolutionsLayout from '../pages/execute-solutions/execute-solutions-page';
import { ExplorationLayout } from '../pages/exploration/exploration-page';
import { ExperimentDetailPage } from '../pages/experiment-details/experiment-detail-page';
import {
  WithDesignContext,
  WithExplorationContext,
} from '../_shared/context/context-helpers';
import { ProjectWorkspaceListPage } from '../pages/project-workspace-list/project-workspace-list-page';
import { WorkspaceLayout } from './workspaces/lab-bench';
import { AdaptiveLearningPanel } from './workspaces/adaptive-learning/adaptive-learning-panel.component';
import { ProjectSetupPage } from '../pages/project-setup/project-setup-pages';
import { CrossProjectLibrary } from '../pages/cross-project-library/cross-project-library';
import { ProjectSummary } from '../pages/project-summary/project-summary';
import { useSession } from '../_shared/context';
import { notification } from 'antd';

const AdminLayout = lazy(() => import('./project/admin/project-admin-layout'));

export const AppRoutes = ({ user }: { user: User }) => {
  const projectPath = '/project/:projectId';

  const { projectId } = useParams();
  const { currentProject, setCurrentProject } = useSession();

  const validatePath = () => {
    if (
      !currentProject ||
      currentProject?.lastSetupStep === 'COMPLETE' ||
      currentProject?.lastSetupStep == null ||
      !user?.enableIceCreamBetaFeatures
    ) {
      return true;
    }
    //setCurrentProject(undefined)
    return false
  }


  const enabledProjectRoutes = user.enableIceCreamBetaFeatures
    ? [
        <Route
          key="project-dahsboard"
          index
          element={<Navigate to="experiments" />}
        />,
        [
          //'overview',
          'experiments',
          'history',
        ].map(path => (
          <Route
            key={`project-experiment-${path}`}
            path={path}
            element={
              <ExecuteSolutionsContextProvider>
                <ExecuteSolutionsLayout />
              </ExecuteSolutionsContextProvider>
            }
          />
        )),
        ['explore', 'explore/:iterationId/:tab?'].map(path => (
          <Route
            key={`project-exploration-${path}`}
            path={path}
            element={
              <WithExplorationContext>
                <ExplorationLayout />
              </WithExplorationContext>
            }
          />
        )),
        <Route
          key="project-experiment-detail"
          path="experiment/:experimentId"
          element={
            <WithDesignContext>
              <ExperimentDetailPage />
            </WithDesignContext>
          }
        />,

        <Route
          key="project-experiment-detail"
          path="experiment/:experimentId"
          element={
            <WithDesignContext>
              <ExperimentDetailPage />
            </WithDesignContext>
          }
        />,
      ]
    : [
        <Route
          index
          key="project-dashboard"
          element={<ProjectWorkspaceListPage />}
        />,
        <Route
          key="project-workspace"
          path="iteration/:iterationId/*"
          element={<WorkspaceLayout />}
        />,
        <Route
          path="expert-knowledge"
          key="project-expert-knowledge"
          element={<ExpertKnowledgeLayout />}
        />,
        <Route
          key="project-expert-summary"
          path="summary-view"
          element={<SummaryViewLayout />}
        />,
      ];

  const handleIndexLoad = () => {
    setCurrentProject(undefined);
  };

  return (
    <Routes>
      <Route
        index
        key="project-listing"
        element={
          user.enableIceCreamBetaFeatures ? (
            <ProjectListLayoutV2 onIndexLoad={handleIndexLoad} />
          ) : (
            <ProjectListLayout />
          )
        }
      />
      <Route
        path="/project/inspect/:projectId/:step"
        key={`project-inspect`}
        element={<ProjectSetupPage edit={false} />}
      />
      {[
        '/project/new',
        '/project/new/:projectId',
        '/project/edit/:projectId',
        '/project/edit/:projectId/:step',
      ].map(path => (
        <Route
          path={path}
          key={`project-new-${path}`}
          element={
            user.enableIceCreamBetaFeatures ? (
              <ProjectSetupPage edit={true} />
            ) : (
              <ProjectSetup />
            )
          }
        />
      ))}
      {
        // user.enableIceCreamBetaFeatures && (
        //   <Route
        //     path="cross-project-library/:userId"
        //     key="cross-project-ingredient-library"
        //     element={
        //       <CrossProjectLibrary />
        //     }
        //   />
        // )
      }

      <Route
        path="/project/:projectId"
        key="project-path-routes"
        element={
          validatePath() ? <ProjectLayout /> : <Navigate to="/" replace />
        }
      >
        {enabledProjectRoutes}
        <Route
          key="project-detail"
          path="details"
          element={<ProjectBasicInfo />}
        />
        <Route key="project-admin" path="admin" element={<AdminLayout />} />
      </Route>
      {/* <Route
        path="/summary/:projectId"
        key="project-summary"
        element={<ProjectSummary />}
      >
      </Route> */}
      <Route
        path="*"
        key="default"
        element={
          <NotFoundComponent
            message="The page you are looking for is not found"
            showAllProjectsLink
          />
        }
      />
    </Routes>
  );
};
