import React, { ReactNode, useEffect, useState } from 'react';
import './project-non-negotiable-constraints.less';
import { Button, Drawer } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { PlusButton, RedCancel } from '../../../../_shared/style';
import { ConstraintType } from '../../../../../../__generated__/globalTypes';
import { useSession } from '../../../../_shared/context';
import { ExplorationConstraintSettingV2 } from '../../../../components/exploration/exploration-contraint-setting-v2/exploration-constraint-setting-v2';
import { useDeleteConstraint, useGetConstraint } from '../../../../network/services/constraint.service';
import { IngredientGroup } from '../../../../components/project/ingredients-group/ingredients-group';
import { ProjectNonNegotiableConstraintsRow } from './project-non-negotiable-const-row/project-non-negotiable-const-row';

export const emptyConstraint = {
  id: '',
  constraintType: ConstraintType.EQUALITY,
  lowerBounds: null,
  upperBounds: null,
  coefficients: [],
  values: [],
  variables: [],
};

export const ProjectNonNegotiableConstraints = ({
  constraints,
  ingredients,
  projId,
  edit,
  designs
}: {
  constraints?: any[];
  ingredients?: any[];
  projId?: string;
  edit?: boolean;
  designs?: any[]
}) => {
  const [isInspectingProject, setIsInspectingProject] = useState<boolean>(edit === false)
  const [ingredientsList, setIngredientsList] = useState<any[]>([]);
  const [constraintsList, setConstraintsList] = useState<any[]>([]);
  const [tableBody, setTableBody] = useState<ReactNode>();
  const [open, setOpen] = useState(false);
  const deleteMutation = useDeleteConstraint();
  const { user, useFetchProject } = useSession();
  const [fetchProjectById] = useFetchProject();
  const { currentProject } = useSession();
  const { data, isLoading, isSuccess, refetch } = useGetConstraint({ organizationId: currentProject?.organizationId!, projectId: projId! })


  useEffect(() => {
    if (ingredients) {
      setIngredientsList(ingredients ? ingredients : []);
    }
  }, [ingredients]);

  useEffect(() => {

    // if (isInspectingProject && designs && designs?.length > 0) {
    //   if (isSuccess) {
    //     const lastDesign = designs[0].id
    //     setConstraintsList(data.filter((c: any) => (
    //       c.designs.length > 0 && c.designs[c.designs.length - 1].id === lastDesign && c.isNonNegotiable
    //     )) ?? [])
    //   }
    // }
    if (isInspectingProject) {
      if (isSuccess) {
        setConstraintsList(data.filter((c: any) => (!c.iterationId && c.isNonNegotiable)))
      }
    }
    else if (constraints) {
      setConstraintsList(constraints ? constraints : []);
      fetchProjectById({
        variables: {
          projectId: `${projId}`,
        },
      });
    }

  }, [data, constraints]);

  const AddConstraintToTable = (constraint: any) => {
    HandleAdd(constraint);
  };

  const HandleAdd = (constraint: any) => {
    constraintsList.push(constraint);
    const aux = [...constraintsList];
    setConstraintsList(aux);
  };

  useEffect(() => {
    const tableBodyRender = constraintsList.map(row => HandleAddRowByType(row));
    setTableBody(tableBodyRender);
  }, [constraintsList]);

  const HandleAddRowByType = (row: any) => {
    switch (row.constraintType) {
      case 'EQUALITY':
        return (
          <div className="row-table">
            <div className="name">
              {row.name} {row.ingredientGroupId ? '[GROUP]' : ''}
            </div>
            <div className="attribute">
              {row.values.map((value: any) =>
                value.name ? value.name + '' : ''
              )}
            </div>
            <div className="value">
              {row.values.map(
                (value: any) => `Target value of ${value.value ?? value.name}`
              )}
            </div>
            {!isInspectingProject &&
              <div className="name">
                <div className="action-buttons-container">
                  <div
                    className="action-button"
                    onClick={() => handleDelete(row.id)}
                  >
                    <RedCancel />
                  </div>

                </div>
                {/* <Button
                icon={<DeleteOutlined />}
                onClick={() => handleDelete(row.id)}
              ></Button> */}
              </div>
            }
          </div>
        );
      case 'RANGE':
        return (
          <div className="row-table">
            <div className="name">
              {row.name} {row.ingredientGroupId ? '[GROUP]' : ''}
            </div>
            <div className="attribute">
              {row.coefficients.map((value: any) =>
                value.name ? value.name + '' : ''
              )}
            </div>
            <div className="value">
              {`Between ${row.lowerBounds} and ${row.upperBounds}`}
            </div>
            {!isInspectingProject &&
              <div className="name">
                <div className="action-buttons-container">
                  <div
                    className="action-button"
                    onClick={() => handleDelete(row.id)}
                  >
                    <RedCancel />
                  </div>
                </div>
                {/* <Button
                icon={<DeleteOutlined />}
                onClick={() => handleDelete(row.id)}
              ></Button> */}
              </div>
            }
          </div>
        );
      case 'COUNT':
        return (
          <div className="row-table">
            <div className="name">
              {row.name} {row.ingredientGroupId ? '[GROUP]' : ''}
            </div>
            <div className="attribute">
              {row.variables.map((value: any) => (value ? value + ' ' : ' '))}
            </div>
            <div className="value">
              {row.ingredientGroupId ? (
                row.lowerBounds == row.upperBounds ? (
                  <>{`All input from the group`}</>
                ) : (
                  <>{`Some input from the group`}</>
                )
              ) : (
                <>{`At least ${row.lowerBounds} and at most ${row.upperBounds}`}</>
              )}
            </div>
            {!isInspectingProject &&
              <div className="name">
                <div className="action-buttons-container">
                  <div
                    className="action-button"
                    onClick={() => handleDelete(row.id)}
                  >
                    <RedCancel />
                  </div>
                </div>
              </div>
            }
          </div>
        );
    }
  };

  const handleDelete = (idTodelete: string) => {
    deleteMutation.mutate(
      {
        organizationId: user?.organizationId ?? '',
        projectId: projId ?? '',
        constraintId: idTodelete,
      },
      {
        onSuccess: async response => {
          if (response) {
            let aux = constraintsList;
            aux = aux.filter(elem => elem.id !== idTodelete);
            setConstraintsList(aux);
          }
          // setConstraintsList([])
          // refetchConst()
        },
      }
    );
  };

  const handleAddNewConstraint = () => {
    setOpen(true);
  };

  const handleOnClose = () => {
    setOpen(false);
  };

  return (
    <div id="non-constraint-project">
      <div id="non-constriant-table">
        <div id="header" className="row-table">
          <div className="name">Name</div>
          <div className="attribute">Attribute</div>
          <div className="value">Value</div>
          {!isInspectingProject && <div className="name"></div>}
        </div>
        {tableBody}
      </div>
      {!isInspectingProject &&
        <>
          <div className="add-constraint-box">
            <div className="plus-button">
              <PlusButton />
            </div>
            <p>Please add a new constraint.</p>
            <Button
              icon={<PlusOutlined />}
              onClick={handleAddNewConstraint}
              className="add-constraint-button"
            >
              Add Constraint
            </Button>
          </div>
          <Drawer
            title="New Constraint"
            onClose={handleOnClose}
            open={open}
            width={524}
          >
            {/* <ExplorationConstraintSettingV2 addConstraintToTable={AddConstraintToTable} constraints={constraintsList} /> */}
            <ProjectNonNegotiableConstraintsRow
              constraint={emptyConstraint}
              index={0}
              defaultShowBody={true}
              onCancel={e => {
                handleOnClose();
              }}
              ingredientsList={ingredientsList}
              projId={projId}
              onConstrainAdd={AddConstraintToTable}
            />
            {/* <IngredientGroup proj_id={projId}></IngredientGroup>*/}
          </Drawer>
        </>
      }
    </div>
  );
};
