import { useCallback, useEffect, useState } from 'react';
import io, { Socket } from 'socket.io-client';
import { useSession } from '../context';
import { IMessage } from '../types';

const SOCKET_URL = __BASE_URL__;

const useWebSocket = () => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isConnecting, setIsConnecting] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [messages, setMessages] = useState<IMessage>();

  const { user } = useSession();

  useEffect(() => {
    const socketInstance = io(SOCKET_URL, {
      transports: ["websocket"]
    });

    setSocket(socketInstance);

    socketInstance.on('connect', () => {
      setIsConnected(true);
      setIsConnecting(false);
      setError(null);
    });

    socketInstance.on('messageFromServer', (message) => {
      if (message.userId === user?.id) {
        setMessages(message);
      }
    });

    socketInstance.on('disconnect', () => {
      setIsConnected(false);
    });

    socketInstance.on('connect_error', (err: Error) => {
      setIsConnected(false);
      setIsConnecting(false);
      setError(`Connection Error: ${err.message}`);
    });

    return () => {
      if (socketInstance) {
        socketInstance.disconnect();
      }
    };
  }, []);

  const emit = useCallback((event: string, ...args: any[]) => {
    if (socket) {
      socket.emit(event, ...args);
    }
  }, [socket]);

  return { socket, isConnected, isConnecting, error, emit, messages };
};

export default useWebSocket;