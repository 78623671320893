
import {
  Button,
  Divider,
  Input,
  InputNumber,
  message,
  notification,
  Select,
  Switch,
} from 'antd';
import React, { useEffect, useState } from 'react';
import {
  IngredientFromGroup,
  IngredientGroupData,
  IngredientGroupFormData,
} from '../ingredients-group.interfaces';
import './ingredient-group-form.less';
import _ from 'lodash';
import { RedCancel } from '../../../../_shared/style';

interface IngredientGroupFormProps {
  ingredientsList: {
    id: number;
    name: string;
  }[];
  addGroup: (element?: IngredientGroupData) => void;
  element: IngredientGroupFormData;
}

export const IngredientGroupForm: React.FC<IngredientGroupFormProps> = ({
  ingredientsList,
  element,
  addGroup,
}) => {
  const [validData, setValidData] = useState(false);
  const [newData, setNewData] = useState<IngredientGroupFormData>({
    ...element,
  });
  const [selectedIngredients, setSelectedIngredients] = useState<
    IngredientFromGroup[]
  >([]);
  const [newIngredientList, SetNewIngredientList] = useState<
    { id: number; name: string }[]
  >([]);

  useEffect(() => {
    if (ingredientsList.length > 0) {
      SetNewIngredientList(ingredientsList);
    }
  }, [ingredientsList]);

  const onInputListener = (prop: keyof IngredientGroupFormData) => (
    value: string | number | boolean
  ) => {
    setNewData((newData: IngredientGroupFormData) => ({
      ...newData,
      [prop]: value,
    }));
  };

  const onClickAddIngredientToGroup = () => {
    // if (!validateAddIngredient()) {
    //   return;
    // }

    const { ingredientId, ingredientName, percentage } = newData;

    if (!ingredientName || !ingredientId || percentage < 0) {
      message.error('Please select an ingredient')
      return;
    }

    setSelectedIngredients([
      ...selectedIngredients,
      {
        ingredientId,
        ingredientName,
        percentage,
      },
    ]);

    setNewData({
      ...newData,
      ingredientId: 0,
      ingredientName: '',
      percentage: 1,
    });

    SetNewIngredientList(newIngredientList.filter(i => i.id !== ingredientId));
  };

  const validateAddIngredient = () => {

    const percentages = selectedIngredients.map(i => i.percentage);

    if (_.sum(percentages) + newData.percentage > 100) {
      notification.error({
        message: `Error adding ${newData.ingredientName}`,
        description: <>{'Percentage sum is more than 100'}</>,
      });
      return false;
    }

    return true;
  };

  const validateAddGroup = () => {
    if (selectedIngredients?.length <= 1) {
      notification.error({
        message: `Error adding ${newData.name}`,
        description: <>{'Group should have at leaste 2 ingredients'}</>,
      });
      return false;
    }

    return true;
  };

  const handleDeleteIngredient = (ing: IngredientFromGroup) => {
    const aux = selectedIngredients.filter(i => i.ingredientId !== ing.ingredientId);
    SetNewIngredientList([...newIngredientList, { name: ing.ingredientName, id: ing.ingredientId }]);
    setSelectedIngredients(aux);
  }

  return (
    <div className="margin-top-1rem">
      <div className='group-description'>
        <Input
          placeholder="Group Name"
          className='group-name'
          value={newData.name}
          onChange={e => onInputListener('name')(e.target.value)}
        />
        <div className='label'>
          Group Sum
        </div>
        <div className='group-sum'>
          <Switch
            size={'small'}
            checked={newData.groupSum}
            onChange={e => onInputListener('groupSum')(e)}
          />
        </div>
      </div>
      <div className="margin-top-1rem">
        <div className="percentage-form-container">
          <Select
            value={newData.ingredientName}
            options={newIngredientList.map(i => ({
              value: i.id,
              label: i.name,
            }))}
            onSelect={(e, o) => {
              onInputListener('ingredientId')(e);
              onInputListener('ingredientName')(o.label);
            }}
            className='ingredients'
          />
          {!newData.groupSum && <div className='coeficient'>
            <InputNumber
              min={0}
              value={newData.percentage}
              onChange={e => onInputListener('percentage')(e ?? 0)}
              max={1}
              step="0.0001"
              stringMode
            />
            <span>Coeficient</span>
          </div>}

        </div>
        <div className="actions-group-container margin-top-1rem">
          <Button
            onClick={onClickAddIngredientToGroup}
            disabled={newData.percentage <= 0 || newData.ingredientId == 0}
            type="primary"
            className="base-button margin-bottom-1rem"
          >
            {`Add Ingredient to group`}
          </Button>
          {selectedIngredients.length > 0 && <>Ingredients in this Group:</>}
          {selectedIngredients.map(i => (
            <div className="ingredient-list-group">
              {`${i.ingredientName} ${newData?.groupSum ? `` : `- coeficient: ${i.percentage}`} `}
              <span onClick={() => handleDeleteIngredient(i)}>
                <RedCancel />
              </span>
            </div>
          ))}
          <Button
            onClick={() => {
              if (!validateAddGroup()) {
                return;
              }

              addGroup({
                name: newData.name,
                ingredient: selectedIngredients,
                lowerBound: 0,
                upperBound: 100,
                groupSum: newData.groupSum
              });
            }}
            disabled={selectedIngredients.length === 0}
            type="primary"
            className="margin-top-1rem base-button"
          >
            Create Group
          </Button>
        </div>
      </div>
      <Divider></Divider>
    </div>
  );
};
