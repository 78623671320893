import React, { useEffect, useState } from 'react';
import { IRawDataAggregated } from '../RawDataAggregated.interfaces';
import { Button, Input, InputNumber, notification, Select } from 'antd';
import {
  DoubleRightOutlined,
  MinusOutlined,
  PauseOutlined,
} from '@ant-design/icons';
import { GreenCheck, RedCancel } from '../../../../../_shared/style';
import _, { min, max } from 'lodash';
import { RawDataVariableTypeOfVariable } from '@prisma/client';
import { disable } from 'mixpanel-browser';
const { Option } = Select;

interface RawDataAggregatedRowProps {
  data: IRawDataAggregated;
  addMode?: boolean;
  editDataListener: (edited: IRawDataAggregated) => void;
  deleteDataListener: (_id: string) => void;
  projectHasData?: boolean;
  edit?: boolean;
}

export const ProjectRawDataAggregatedRow: React.FC<RawDataAggregatedRowProps> = ({
  data,
  addMode,
  editDataListener,
  deleteDataListener,
  projectHasData,
  edit,
}) => {
  const [inspectMode, setInspectMode] = useState(edit === false);
  const [editMode, setEditMode] = useState(false);
  const [newData, setNewData] = useState<IRawDataAggregated>({ ...data });
  const [errors, setErrors] = useState<
    { [key in keyof IRawDataAggregated]?: string }
  >({});

  const handleEditMode = () => {
    if (!addMode && !editMode && !inspectMode) {
      setEditMode(true);
    }
  };

  const handleAddNew = () => {
    if (!validateRow()) return;

    editDataListener(newData);
    setEditMode(false);
  };

  const handleEdit = () => {
    if (!validateRow()) return;

    editDataListener(newData);
    setEditMode(false);
  };

  const handleDelete = () => {
    deleteDataListener(data.id);
    setEditMode(false);
  };

  const onInputListener = (prop: keyof IRawDataAggregated) => (
    value: string | number
  ) => {
    if (prop === 'valuesDetected' && typeof value === 'string') {
      const values = value.split(',').map(v => Number(v));

      if (!Number.isNaN(values[0])) {
        const minValue = min(values);
        const maxValue = max(values);

        setNewData((newData: IRawDataAggregated) => ({
          ...newData,
          [prop]: value,
          lowerBound: minValue !== undefined ? minValue : newData.min,
          upperBound: maxValue !== undefined ? maxValue : newData.max,
        }));

        return;
      }
    }

    setNewData((newData: IRawDataAggregated) => ({
      ...newData,
      [prop]: value,
    }));
  };

  const validateRow = () => {
    const messages: string[] = [];
    const newErrors: { [key in keyof IRawDataAggregated]?: string } = {};
    const propertiesToCheck: (keyof IRawDataAggregated)[] = ['variable'];

    if (newData.dataType == 'NUMERIC') {
      Object.entries(newData).forEach(([k, v]) => {
        console.log(propertiesToCheck.includes(k as keyof IRawDataAggregated));
        if (!v && propertiesToCheck.includes(k as keyof IRawDataAggregated)) {
          messages.push(`${k} is missing`);
        }
      });

      if (newData.lowerBound > newData.upperBound) {
        messages.push('lower bound is higher than upper bound');
        newErrors.lowerBound = 'Invalid bounds';
        newErrors.upperBound = 'Invalid bounds';
      } else if (newData.lowerBound === newData.upperBound) {
        messages.push('Lower bound and upper bound must be different values');
        newErrors.lowerBound = 'Invalid bounds';
        newErrors.upperBound = 'Invalid bounds';
      } else if (newData.min > newData.max && projectHasData) {
        messages.push('min value observed is higher than max value observed');
        newErrors.min = 'Invalid min';
        newErrors.max = 'Invalid max';
      } else if (newData.lowerBound > newData.min && projectHasData) {
        messages.push(
          'Min Value out of bounds: it must be lower than min observed value'
        );
        newErrors.lowerBound = 'Invalid min';
      } else if (newData.upperBound < newData.max && projectHasData) {
        messages.push(
          'Max Value out of bounds: it must be greater than max observed value'
        );
        newErrors.upperBound = 'Invalid max';
      }
    } else {
      if (newData.dataType == 'ORDINAL') {
        if (newData.valuesDetected) {
          const arrValues = newData.valuesDetected.split(',');
          console.log(arrValues);
          arrValues[0] === arrValues[arrValues.length - 1] &&
            messages.push(
              'Lower and Upper Limit cannot be the same for ORDINAL variables'
            );
        }
      }

      if (!newData.valuesDetected) {
        messages.push('Values expected');
      } else if (newData.values) {
        // Values detected
        let valuesDetectedArray = newData.valuesDetected.split(',');
        for (let v in valuesDetectedArray) {
          v = v.trim();
        }
        // Values observed
        let values = newData.values.split(',');
        for (let value in values) {
          if (!(value.trim() in valuesDetectedArray)) {
            messages.push('All observed values must be in values');
            newErrors.values = 'Mismatch in values';
          }
        }
      }
    }
    setErrors(newErrors);

    if (messages.length > 0) {
      notification.error({
        message: 'Error on editing row',
        description: (
          <>
            {messages.map(m => (
              <>
                {m}
                <br />
              </>
            ))}
          </>
        ),
      });
      return false;
    } else {
      return true;
    }
  };

  return (
    <div
      onClick={handleEditMode}
      className={`row-table ${addMode || editMode ? 'addingRow' : ''}`}
    >
      <div
        className="category"
        style={{
          position: 'sticky',
          left: 0,
          zIndex: 9999,
          backgroundColor: '#fff',
        }}
      >
        {addMode || (!projectHasData && editMode) ? (
          <Input
            placeholder="Water"
            value={newData.variable}
            onChange={e => onInputListener('variable')(e.target.value)}
            className={errors.variable ? 'input-error' : ''}
          />
        ) : (
          newData.variable
        )}
      </div>

      <div className="name">
        <Select
          value={newData.typeOfVariable ?? 'SELECT'}
          disabled={!editMode && !addMode}
          className="select-input-type"
          options={Object.keys(RawDataVariableTypeOfVariable)
            .filter(
              type => type !== RawDataVariableTypeOfVariable.NEW_INGREDIENT
            )
            .map(type => ({
              value: type,
              label: type.replace('_', ' '),
              disable: false,
            }))}
          onSelect={e => {
            if (e.includes('OUTCOME')) {
              newData.priorityOutcome = 1;
            } else {
              newData.priorityOutcome = 0;
            }
            onInputListener('typeOfVariable')(e);
          }}
        />
      </div>

      <div className="test-condition">
        <Select
          value={newData.dataType ?? 'NUMERIC'}
          disabled={
            !editMode && !addMode && newData.typeOfVariable !== 'PROCESSING'
          }
          options={[
            { value: 'NUMERIC', label: 'Continuous' },
            { value: 'CATEGORICAL', label: 'Nominal' },
            { value: 'ORDINAL', label: 'Ordinal' },
          ]}
          onSelect={e => onInputListener('dataType')(e)}
        />
      </div>

      <div className="test-condition">
        {newData.dataType === 'NUMERIC' ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <InputNumber
              placeholder="min"
              value={newData.min}
              disabled={true}
              onChange={e => onInputListener('min')(e ?? 0)}
              suffix={
                newData.typeOfVariable?.includes('INGREDIENT') ? '%' : undefined
              }
            />
            -
            <InputNumber
              placeholder="max"
              value={newData.max}
              disabled={true}
              onChange={e => onInputListener('max')(e ?? 0)}
              suffix={
                newData.typeOfVariable?.includes('INGREDIENT') ? '%' : undefined
              }
            />
          </div>
        ) : (
          <Input
            value={newData.values}
            disabled={true}
            onChange={e => onInputListener('values')(e.target.value)}
          />
        )}
      </div>

      {/* <div className="cost">
        {addMode || editMode ? (
          <InputNumber
            placeholder="1"
            value={newData.max}
            onChange={e => onInputListener('max')(e ?? 0)}
          />
        ) : (
          newData.max
        )}
      </div> */}

      <div className="unit">
        {addMode || editMode ? (
          <Input
            placeholder="%/grm"
            value={newData.unit}
            onChange={e => onInputListener('unit')(e.target.value)}
          />
        ) : (
          newData.unit
        )}
      </div>

      <div className="test-condition">
        {addMode || editMode ? (
          <Input
            placeholder="Liquid"
            value={newData.category}
            onChange={e => onInputListener('category')(e.target.value)}
          />
        ) : (
          newData.category
        )}
      </div>

      <div className="test-condition">
        {addMode || editMode ? (
          <Input
            placeholder="Hydratation"
            value={newData.functionalRole}
            onChange={e => onInputListener('functionalRole')(e.target.value)}
          />
        ) : (
          newData.functionalRole
        )}
      </div>

      <div className="test-condition">
        {addMode || editMode ? (
          <Input
            placeholder="description"
            value={newData.description}
            onChange={e => onInputListener('description')(e.target.value)}
          />
        ) : (
          newData.description
        )}
      </div>

      <div className="cost">
        {addMode || editMode ? (
          <InputNumber
            placeholder="0"
            value={newData.cost}
            onChange={e => onInputListener('cost')(e ?? 0)}
          />
        ) : (
          newData.cost
        )}
      </div>

      <div className="cost">
        {addMode || editMode ? (
          <Input
            placeholder="$/Kg"
            value={newData.costUnit}
            onChange={e => onInputListener('costUnit')(e.target.value)}
          />
        ) : (
          newData.costUnit
        )}
      </div>

      {/* <div className="cost">
        {addMode || editMode ? (
          <InputNumber
            placeholder="lower bound"
            value={newData.lowerBound}
            onChange={e => onInputListener('lowerBound')(e ?? 0)}
          />
        ) : (
          newData.lowerBound
        )}
      </div> */}

      <div className="test-condition">
        {newData.dataType === 'NUMERIC' ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <InputNumber
              placeholder="lower bound"
              value={newData.lowerBound}
              disabled={!addMode && !editMode}
              onChange={e => onInputListener('lowerBound')(e ?? 0)}
              className={errors.lowerBound ? 'input-error' : ''}
              suffix={
                newData.typeOfVariable?.includes('INGREDIENT') ? '%' : undefined
              }
            />
            -
            <InputNumber
              placeholder="upper bound"
              value={newData.upperBound}
              disabled={!addMode && !editMode}
              onChange={e => onInputListener('upperBound')(e ?? 0)}
              className={errors.upperBound ? 'input-error' : ''}
              suffix={
                newData.typeOfVariable?.includes('INGREDIENT') ? '%' : undefined
              }
            />
          </div>
        ) : (
          <Input
            value={newData.valuesDetected}
            onChange={e => onInputListener('valuesDetected')(e.target.value)}
            className={errors.values ? 'input-error' : ''}
          />
        )}
      </div>

      <div className="test-condition">
        {newData.priorityOutcome ? (
          <>
            <Select
              value={newData.priorityOutcome}
              disabled={!(addMode || editMode)}
              onSelect={e => onInputListener('priorityOutcome')(e)}
            >
              <Option value={3}>
                <DoubleRightOutlined
                  style={{
                    transform: 'rotate(-90deg)',
                    marginRight: 5,
                    color: 'red',
                  }}
                />
                High
              </Option>
              <Option value={2}>
                <PauseOutlined
                  style={{
                    transform: 'rotate(90deg)',
                    marginRight: 5,
                    color: 'green',
                  }}
                />
                Medium
              </Option>
              <Option value={1}>
                <DoubleRightOutlined
                  style={{
                    transform: 'rotate(90deg)',
                    marginRight: 5,
                    color: 'blue',
                  }}
                />
                Low
              </Option>
              <Option value={0}>
                <MinusOutlined style={{ marginRight: 5, color: 'grey' }} />
                None
              </Option>
            </Select>
          </>
        ) : (
          <>NA</>
        )}
        {addMode && (
          <Button onClick={handleAddNew} className="add-ingredient">
            + Add
          </Button>
        )}
        {editMode && (
          <div className="action-buttons-container">
            <div className="action-button" onClick={handleEdit}>
              <GreenCheck />
            </div>
            {!projectHasData && (
              <div className="action-button" onClick={() => handleDelete()}>
                <RedCancel />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
